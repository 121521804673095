import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const configSystemMenuRegistry = {
    prices_configSystem: {
        label: "Config System",
        link: PAGE_URL.CONFIG_SYSTEM_PAGE,
        key: PAGE_URL.CONFIG_SYSTEM_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default configSystemMenuRegistry;
